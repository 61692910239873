import React from 'react'

const About = () => {
  return (
    <div>
      Pleases see <a href="https://montaigne.co" target="_blank" rel="noopener noreferrer">Montaigne</a>
    </div>
  )
}

export default About
